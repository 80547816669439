import * as React from "react";
import {Reminder} from "../models/reminder";
const flatpickr = require('flatpickr');

interface PropsType {
    reminder: Reminder,
    handleSave: (r: Reminder) => void
}

interface StateType {
    reminder: Reminder
}

export class EditReminder extends React.Component<PropsType, StateType> {

    constructor(props) {
        super(props);

        this.state = {
            reminder: this.props.reminder
        };

        this.handleSave = this.handleSave.bind(this);
        this.setContent = this.setContent.bind(this);
    }

    componentDidMount() {
        flatpickr('.reminder-date-date', {
            altInput: true,
            altFormat: "F J",
            dateFormat: "Y-m-d",
            defaultDate: this.state.reminder.due,
            onChange: (selectedDates, dateStr, instance) => {
                const selectedDate = selectedDates[0] as Date;
                this.state.reminder.due.setFullYear(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate())
                this.setState({
                    reminder: this.state.reminder
                })
            }
        });
        flatpickr('.reminder-date-time', {
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
            time_24hr: true,
            defaultDate: this.state.reminder.due,
            onChange: (selectedDates, dateStr, instance) => {
                const selectedDate = selectedDates[0] as Date;
                this.state.reminder.due.setHours(selectedDate.getHours(), selectedDate.getMinutes(), 0, 0);
                this.setState({
                    reminder: this.state.reminder
                })
            }
        });
    }

    private handleSave() {
        this.props.handleSave(this.state.reminder);
    }

    private setContent(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.state.reminder.content = (event.target as HTMLTextAreaElement).value;
        this.setState({
            reminder: this.state.reminder
        })
    }

    render() {
        return (
            <div className="field">
                <h4>Adding a reminder...</h4>
                <form>
                    <div className="control is-medium"><textarea className="reminder-textarea"
                                                                 onChange={this.setContent}
                                                                 defaultValue={this.state.reminder.content}/>
                    </div>
                    <div className="reminder-date-wrap">
                        <p className="reminder-date-when">When</p>
                        <input type="text" className="reminder-date-field reminder-date-date" />
                        <input type="text" className="reminder-date-field reminder-date-time" />
                    </div>
                    <div className="reminder-buttons-wrap">
                        <button type="button" className="button submit-btn" onClick={this.handleSave}>Save changes</button>
                    </div>
                </form>
            </div>
        );
    }

}