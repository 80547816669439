import * as React from "react";
import {Reminder} from "../models/reminder";

export default class ReminderComponent extends React.Component<{ reminder: Reminder, handleDelete: any; handleExpand: any; }, {}> {

    constructor(props: Readonly<{ reminder: Reminder; handleDelete: any; handleExpand: any; }>) {
        super(props);
    }

    render() {
        const syncIcon = (this.props.reminder.synced_at) ? "" : "♲";

        return (
            <div className="card">
                <div className="card-content">
                    <p className={"title " + (Reminder.isOverdue(this.props.reminder) ? "overdue" : "")}>
                        {this.props.reminder.content} {syncIcon}
                    </p>
                    <p className={"subtitle " + (Reminder.isOverdue(this.props.reminder) ? "overdue" : "")}>
                        {this.props.reminder.due.toUTCString()}
                        <a href="#" onClick={() => this.props.handleDelete(this.props.reminder.uuid)}>Delete</a>
                    </p>
                    <label className="mark-done checkbox">
                        <input type="checkbox" />
                            Mark as done
                    </label>
                    <img onClick={() => this.props.handleExpand(this.props.reminder.uuid)} className="arrow-down" src="/img/arr_down.svg" />
                </div>
                <footer className="card-footer">
                    <p className="card-footer-item">
      <span>
        <a href="#">Edit</a>
      </span>
                    </p>
                    <p className="card-footer-item">
      <span>

      </span>
                    </p>
                </footer>
            </div>
        )
    }

}