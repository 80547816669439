import * as React from "react";
import {Reminder} from "../models/reminder";
const flatpickr = require('flatpickr');

interface PropsType {
    reminder: Reminder,
    handleCreate: (text: string, date: Date) => void
}

interface StateType {
    content: string,
    due: Date
}

export class NewReminder extends React.Component<PropsType, StateType> {

    constructor(props) {
        super(props);

        const defaultDate = new Date();
        defaultDate.setHours(defaultDate.getHours() + 12)

        this.state = {
            content: "",
            due: defaultDate
        };

        this.handleCreate = this.handleCreate.bind(this);
    }

    componentDidMount(): void {
        flatpickr('.reminder-date-date', {
            altInput: true,
            altFormat: "F J",
            dateFormat: "Y-m-d",
            defaultDate: this.state.due,
            onChange: (selectedDates, dateStr, instance) => {
                const selectedDate = selectedDates[0] as Date;
                this.state.due.setFullYear(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate())
                this.setState({
                    due: this.state.due
                })
            }
        });
        flatpickr('.reminder-date-time', {
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
            time_24hr: true,
            defaultDate: this.state.due,
            onChange: (selectedDates, dateStr, instance) => {
                const selectedDate = selectedDates[0] as Date;
                this.state.due.setHours(selectedDate.getHours(), selectedDate.getMinutes(), 0, 0);
                this.setState({
                    due: this.state.due
                })
            }
        });
    }

    private handleCreate() {
        this.props.handleCreate(this.state.content, new Date(this.state.due));
    }

    render() {
        return (
            <div className="field">
                <h4>Adding a reminder...</h4>
                <form>
                    <div className="control is-medium"><textarea className="reminder-textarea"
                                                                 onChange={evt => this.setState({content: evt.target.value})}
                                                                 placeholder="What do you need to remember?"
                                                                 defaultValue={this.state.content}/>
                    </div>
                    <div className="reminder-date-wrap">
                        <p className="reminder-date-when">When</p>
                        <input type="text" className="reminder-date-field reminder-date-date" />
                        <input type="text" className="reminder-date-field reminder-date-time" />
                    </div>
                    <div className="reminder-buttons-wrap">
                        <button type="button" className="button submit-btn" onClick={this.handleCreate}>Save changes</button>
                    </div>
                </form>
            </div>
        );
    }
}