import {IReminderSync, Reminder} from "../models/reminder";
import Encrypter from "../core/encrypter";

class Api {

    baseUrl: "";

    constructor(url) {
        this.baseUrl = url;
    }

    public async syncReminders(since: number): Promise<IReminderSync> {
        return fetch(this.baseUrl + '/api/v1/reminders?since=' + (since || 0), {
            method: 'GET',
            credentials: 'include'
        })
        .then(response => response.json())
        .then(async (json) => {
            if (json.data) {
                const transform = json.data
                    .map(async (reminder: Reminder) => Reminder.forStorage(reminder));

                json.data = await Promise.all(transform);
            }

            return json;
        });
    }

    public async createReminder(reminder: Reminder): Promise<Response> {
        return Reminder.forApi(reminder)
            .then(body => fetch(this.baseUrl + '/api/v1/reminders', {
                method: 'POST',
                body: JSON.stringify(body),
                credentials: 'include',
                headers: [
                    ['x-sw-callback', 'create.reminder.' + reminder.uuid]
                ]
            }
        ));
    }

    public async updateReminder(reminder: Reminder): Promise<Response> {
        const body = {
            content: reminder.content,
            due: reminder.due,
            updated_at: reminder.updated_at
        };

        return fetch(this.baseUrl + '/api/v1/reminders/' + reminder.uuid, {
            method: 'PUT',
            body: JSON.stringify(body),
            credentials: 'include',
            headers: [
                ['x-sw-callback', 'update.reminder.' + reminder.uuid]
            ]
        });
    }

    public deleteReminder(id: string): Promise<Response> {
        return fetch(this.baseUrl + '/api/v1/reminders/' + id, {
            method: 'DELETE',
            credentials: 'include',
            headers: [
                ['x-sw-callback', 'delete.reminder.' + id]
            ]
        });
    }

    public authenticate(): Promise<Response> {
        return fetch(this.baseUrl + '/api/v1/auth', {
            method: 'GET',
            credentials: 'include',
        });
    }

    public getInviteUrl(): Promise<Response> {
        return fetch(this.baseUrl + '/api/v1/users/invitations', {
            method: 'GET',
            credentials: 'include'
        })
    }

    public syncAccount(key: string): Promise<Response> {
        return fetch(this.baseUrl + '/api/v1/auth/sync', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({key: key}),
            headers: [
                ['content-type', 'application/json']
            ]
        });
    }

    public savePushSubscription(subscription: object): Promise<Response> {
        return fetch(this.baseUrl + '/api/v1/devices/subscription', {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify({subscription: subscription})
        });
    }

}

export default new Api(process.env.API_URL);