import Dexie from "dexie";
import 'dexie-observable';
import {IReminder, Reminder} from "../models/reminder";
import {IQueuedRequest} from "../models/queued_request";
import Migrations from "../db/migrations";
import Encrypter from "../core/encrypter";

class Storage extends Dexie {

    static DB_NAME = "laterdb";

    public reminders: Dexie.Table<IReminder, string>;
    public queuedRequests: Dexie.Table<IQueuedRequest, string>;

    constructor() {
        super(Storage.DB_NAME);
        Migrations.migrate(this);
    }

    public async save(model: IReminder): Promise<string> {
        if (!model.created_at) {
            model.created_at = new Date();
        }
        model.updated_at = new Date();
        model.content = await Encrypter.encrypt(model.content);

        if (!model.uuid) {
            return this.reminders.add(model);
        } else {
            return this.reminders.put(model);
        }
    }

    public async getAll() {
        const decryptedReminders = (await this.reminders.toArray()).map(async reminder => {
            try {
                console.info("decrypting .. " + reminder.content);
                reminder.content = await Encrypter.decrypt(reminder.content);
            } catch(err) {
                console.error(err);
                reminder.content = '<cannot decrypt>';
            }
            return reminder;
        });

        return (await Promise.all(decryptedReminders)).sort((a: IReminder, b: IReminder) => {
            if(Reminder.isOverdue(a) && !Reminder.isOverdue(b)) {
                return 1;
            } else if(!Reminder.isOverdue(a) && Reminder.isOverdue(b)) {
                return -1;
            } else if(Reminder.isOverdue(a) && Reminder.isOverdue(b)) {
                return b.due.getTime() - a.due.getTime();
            }
            return a.due.getTime() - b.due.getTime()
        });
    }

}

export default new Storage;