import * as React from "react";
const QRCode = require('qrcode')
import Api from '../core/api'
import Encrypter from '../core/encrypter'

interface PropsType {
}

interface StateType {
    qr: string
}

export class SyncAccount extends React.Component<PropsType, StateType> {

    constructor(props) {
        super(props);
        this.handleGenerateQR = this.handleGenerateQR.bind(this);
        this.state = {
            qr: null
        }
    }

    componentDidMount(): void {
    }

    private async handleGenerateQR() {
        if(this.state.qr) {
            this.setState({
                qr: null
            });
        } else {
            const salt = await Encrypter.generateSalt();
            const key = await Encrypter.getEncryptedKey("123456", salt);

            Api.getInviteUrl()
                .then(response => response.json())
                .then(response => response.url + ";" + salt + ";" + key)
                .then((url: string) => {
                    console.log(url);
                    return QRCode.toDataURL(url);
                })
                .then(qrImageDataString => {
                    this.setState({
                        qr: qrImageDataString
                    })
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }

    render() {
        return (
            <div>
                <span onClick={this.handleGenerateQR}>📲	</span>
                {this.state.qr ? (
                    <img src={this.state.qr} />
                ) : null}
            </div>
        );
    }
}