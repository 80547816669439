import Dexie from "dexie";

export default class Migrations {

    public static migrate(db: Dexie): void {

        db.version(1).stores({
            reminders: 'uuid, due',
            queuedRequests: 'key, taken'
        });

    }

}