import * as React from "react";
import {NewReminder} from "../components/create_reminder";
import {ReminderList} from "../components/reminders-list";
import Api from "./api";
import {Reminder} from "../models/reminder";
import Storage from "./storage";
import * as localForage from "localforage";
import {SyncAccount} from "../components/sync_account";
import {PushAlert} from "../components/push_alert";
import Encrypter from "./encrypter";
import {AsyncSleep} from "./utils";

export default class App extends React.Component<{}, { showPushAlert: boolean }> {

    public constructor(props) {
        super(props);
        this.state = {
            showPushAlert: false
        };

        this.handleCreate = this.handleCreate.bind(this);
        this.requestPushPermissions = this.requestPushPermissions.bind(this);
    }

    private static async getServiceWorker(): Promise<ServiceWorker> {
        /**
         * @FIXME? Weird weird behavior
         * https://w3c.github.io/ServiceWorker/#navigator-service-worker-controller
         * **Note: navigator.serviceWorker.controller returns null if the request is a force refresh (shift+refresh).**
         */
        let sw = (await navigator.serviceWorker.getRegistration()).active;
        // if no active SW at the moment, it must mean it's still due to activate...
        if(!sw) {
            let i = 0;
            const maxRetries = 10;
            do {
                if(navigator.serviceWorker.controller != null) {
                    sw = navigator.serviceWorker.controller;
                    console.log('sleeping and retrying...');
                    break;
                }
                await AsyncSleep(500);
            }
            while(++i < maxRetries);
        }

        if(sw) {
            return sw;
        } else {
            throw new Error('Could not obtain active service worker!');
        }
    }

    private async requestPushPermissions() {
        const result = await Notification.requestPermission();
        const allowed = result === 'granted';

        if (allowed) {
            (await App.getServiceWorker()).postMessage({action: 'PUSH_API_ALLOWED'});
        }

        this.setState({
            showPushAlert: !allowed
        });
    }

    async componentDidMount(): Promise<void> {
        await Api.authenticate();

        const reg = await navigator.serviceWorker.register('/sw.js');
        console.log("SW REG: " + reg);

        await this.requestPushPermissions();

        if (window.location.hash) {
            if (window.location.hash.startsWith("#i/")) {
                const urlData = window.location.hash.substr(3).split(";");
                console.log("#invitation found, syncing accounts: " + urlData[0]);
                window.history.replaceState("", "", window.location.href.slice(0, window.location.href.indexOf("#")));
                Api.syncAccount(urlData[0]).then(async (res: Response) => {
                    if (res.ok) {
                        let encryptionKey;
                        try {
                            encryptionKey = await Encrypter.loadEncryptedKey(urlData[2], "123456", urlData[1]);
                        } catch {
                            console.error("key could not be extracted.");
                        }
                        console.log("KEY RECEIVED:");
                        console.log(encryptionKey);

                        Promise.all([
                            localForage.setItem("encryptionKey", encryptionKey),
                            localForage.setItem("revision", 0)
                        ]).then(async () => {
                            (await App.getServiceWorker()).postMessage({action: 'SYNC'})
                            console.log("woop accounts synced!");

                        }).catch(err => {
                            console.error(err);
                        })
                    }
                }).catch(err => {
                    console.error(err);
                });
            } else {
                const r = Reminder.createFromInput(decodeURIComponent(window.location.hash).substr(1));
                const savedReminder = await Storage.save(r);
                if (savedReminder) {
                    window.history.replaceState("", "", window.location.href.slice(0, window.location.href.indexOf("#")));
                    await Api.createReminder(r);
                }
            }
        }


    }

    private handleCreate(content: string, due: Date): void {
        const reminder = new Reminder(content, due);

        Storage.save(reminder).then(() => {
            Api.createReminder(reminder);
        }).catch(err => {
            console.error(err);
        });
    }

    render(): React.ReactNode {
        return (
            <div>
                {this.state.showPushAlert ? <PushAlert handleEnable={this.requestPushPermissions}/> : null}
                <SyncAccount/>
                <NewReminder handleCreate={this.handleCreate} reminder={null}/>
                <ReminderList/>
            </div>
        )
    }

}