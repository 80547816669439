import * as Chrono from "../node_modules/chrono-node/chrono";
import uuid = require("uuid");

export interface IReminderSync {
    data: IReminder[]
    removals: string[]
    meta: {
        rev: number
    }
}

export interface IReminder {
    uuid: string,
    content: string
    draft?: boolean
    due: Date
    notified_at?: Date
    dismissed_at?: Date
    synced_at: Date
    created_at?: Date
    updated_at?: Date
}

export class Reminder implements IReminder {
    uuid: string;
    content: string;
    draft?: boolean;
    due: Date;
    notified_at?: Date;
    dismissed_at?: Date;
    synced_at: Date;
    created_at?: Date;
    updated_at?: Date;

    public constructor(content: string, due: Date) {
        this.uuid = '_' + uuid.v4();
        this.content = content;
        this.due = due;
        this.draft = true;
        this.synced_at = null;
    }

    public static isOverdue(reminder: IReminder): boolean {
        return reminder.due < (new Date());
    }

    public static async forApi(reminder: IReminder): Promise<object> {
        return {
            content: reminder.content,
            due: reminder.due,
            created_at: reminder.created_at,
            updated_at: reminder.updated_at
        };
    }

    public static async forStorage(reminder: IReminder): Promise<IReminder> {
        reminder.due = new Date(reminder.due);
        reminder.created_at = new Date(reminder.created_at);
        reminder.updated_at = new Date(reminder.updated_at);

        return reminder;
    }

    public static createFromInput(input: string) : Reminder {
        const parsed = Chrono.parse(input);
        return new Reminder(input.replace(parsed[0].text, '').trim(), parsed[0].start.date());
    }

}