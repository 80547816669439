import * as localForage from "localforage";

class Encrypter {

    private encryptionKey: CryptoKey;

    public constructor() {
        this.getKey().then(key => {
            this.encryptionKey = key;
        }).catch(err => {
            console.error(err);
        });
    }

    private static generateKey(): ArrayBuffer {
        return crypto.getRandomValues(new Uint8Array(16));
    }

    private async getKey(): Promise<CryptoKey> {
        return localForage.getItem("encryptionKey").then(async (value: ArrayBuffer) => {
            if (!value) {
                value = Encrypter.generateKey();
                await localForage.setItem("encryptionKey", value);
            }

            return Encrypter.importKey(value, {name: "AES-GCM", length: 128});
        });
    }

    public loadEncryptedKey(encryptedKey: string, passphrase: string, salt: string) {
        const passphraseForDecryption = new TextEncoder().encode(passphrase);
        const saltArray = new TextEncoder().encode(salt);

        return Encrypter.importKey(passphraseForDecryption, "PBKDF2", false, ["deriveKey"])
            .then((key: CryptoKey) => Encrypter.deriveKey(key, saltArray, false))
            .then((key: CryptoKey) => this.decryptData(encryptedKey, key))
            .then((serialisedKey: string) => new Uint8Array(Encrypter.unserialise(serialisedKey)))
            .catch(err => {
                console.error(err);
            });
    };

    public async getEncryptedKey(passphrase: string, salt: string) {
        const passphraseForEncryption = new TextEncoder().encode(passphrase);
        const saltArray = new TextEncoder().encode(salt);
        const serialisedKey = Encrypter.serialise(await localForage.getItem("encryptionKey"));

        return Encrypter.importKey(passphraseForEncryption, "PBKDF2", false, ["deriveKey"])
            .then((key: CryptoKey) => Encrypter.deriveKey(key, saltArray, false))
            .then((key: CryptoKey) => this.encryptData(serialisedKey, key))
            .catch(err => {
                console.error(err);
            });
    }

    private static async importKey(key: ArrayBuffer, alg: AesKeyAlgorithm | string, extractable: boolean = false, usages: string[] = ["encrypt", "decrypt"]): Promise<CryptoKey> {
        return await crypto.subtle.importKey("raw", key, alg, extractable, usages);
    }

    public generateSalt(len = 8): string {
        return Encrypter.serialise(crypto.getRandomValues(new Uint8Array(len)));
    }

    private static async deriveKey(key: CryptoKey, salt: Uint8Array, extractable: boolean): Promise<CryptoKey> {
        return await crypto.subtle.deriveKey(
            {
                "name": "PBKDF2",
                salt: salt,
                iterations: 250000,
                hash: {name: "SHA-256"},
            },
            key,
            {
                name: "AES-GCM", //can be any AES algorithm ("AES-CTR", "AES-CBC", "AES-CMAC", "AES-GCM", "AES-CFB", "AES-KW", "ECDH", "DH", or "HMAC")
                length: 256, //can be  128, 192, or 256
            },
            extractable, //whether the derived key is extractable (i.e. can be used in exportKey)
            ["encrypt", "decrypt"] //limited to the options in that algorithm's importKey
        )
    }

    private async encryptData(data: string, key: CryptoKey) {

        const dataToEncrypt = new TextEncoder().encode(data);

        const iv = crypto.getRandomValues(new Uint8Array(12));

        const encrypted = await crypto.subtle.encrypt(
            {
                name: "AES-GCM",
                iv: iv,
            },
            key, //from generateKey or importKey above
            dataToEncrypt //ArrayBuffer of data you want to encrypt
        );

        return 'enc:gcm:' + Encrypter.serialise(iv) + ':' + Encrypter.serialise(encrypted);
    }

    private async decryptData(data: string, key: CryptoKey) {

        const enc = data.split(":");
        const iv = Encrypter.unserialise(enc[2]);
        const dataToDecrypt = Encrypter.unserialise(enc[3]);

        const decrypted = await crypto.subtle.decrypt(
            {
                name: "AES-GCM",
                iv: iv,
            },
            key, //from generateKey or importKey above
            dataToDecrypt //ArrayBuffer of data you want to encrypt
        );

        return (new TextDecoder).decode(decrypted);
    }

    public async encrypt(content: string): Promise<string> {
        return await this.encryptData(content, await this.getKey());
    }

    public async decrypt(content: string): Promise<string> {
        return await this.decryptData(content, await this.getKey());
    }

    private static serialise(data: ArrayBuffer): string {
        return btoa(String.fromCharCode.apply(null, new Uint8Array(data)));
    }

    private static unserialise(data: string): ArrayBuffer {
        try {
            data = atob(data);
            const buffer = new ArrayBuffer(data.length);
            const bufferView = new Uint8Array(buffer);
            for (let i = 0, len = data.length; i < len; i++) {
                bufferView[i] = data.charCodeAt(i);
            }

            return buffer;
        } catch (err) {
            return null;
        }
    }

}

export default new Encrypter();