export class RequestSerializer {

    public static async serialize(request: Request): Promise<string> {
        let headers: object = {};
        request.headers.forEach((value, key) => {
            if(!key.startsWith("x-sw-")) {
                headers[key] = value;
            }
        });

        let storeRequest = {
            url: request.url,
            method: request.method,
            body: (await request.text()),
            headers: headers
        };

        ["mode", "credentials"].forEach(param => {
            if(request[param]) {
                storeRequest[param] = request[param];
            }
        });

        return JSON.stringify(storeRequest);
    }

    public static unserialize(jsonRequest: string): Request {
        const reqInit = JSON.parse(jsonRequest) as Request;

        return new Request(reqInit.url, reqInit);
    }

}

export const AsyncSleep = (milliseconds: number) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
};

export class Merge {

    public static objects(obj1: object, obj2: object, keys?: string[]) {
        Object
            .keys(obj2)
            .filter(key => keys.length === 0 || keys.indexOf(key) !== -1)
            .forEach(key => obj1[key] = obj2[key]);

        return obj1;
    }

}