import * as React from "react";

interface PropsType {
    handleEnable: (x: any) => void
}

interface StateType {
}

export class PushAlert extends React.Component<PropsType, StateType> {

    constructor(props) {
        super(props);
        this.handleEnable = this.handleEnable.bind(this);
    }

    componentDidMount(): void {
    }

    private handleEnable() {
        this.props.handleEnable(null);
    }

    render() {
        return (
            <section className="hero is-warning">
                <div className="hero-body">
                    <div className="container">
                        <h1 className="title">
                            😰 Allow notifications?
                        </h1>
                        <h3 className="subtitle">
                            later.do will not be able to send you push notifications! <a onClick={this.handleEnable}>Enable them in your browser</a>
                        </h3>
                    </div>
                </div>
            </section>
        );
    }
}