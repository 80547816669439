import {Reminder} from "../models/reminder";
import * as React from "react";
import ReminderComponent from "./reminder";
import Storage from "../core/storage";
import Api from "../core/api";
import {EditReminder} from "./edit_reminder";

export class ReminderList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            reminders: [],
            editing: null
        };

        this.remove = this.remove.bind(this);
        this.expand = this.expand.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount(): void {
        this.loadData();
        Storage.on('changes', () => {
            this.loadData();
        });
    }

    private async remove(id: string): Promise<void> {
        await Storage.reminders.delete(id);
        if (id.substr(0, 1) !== "_") {
            Api.deleteReminder(id);
        }
        const filtered = this.state['reminders'].filter(r => r.uuid !== id);
        this.setState({
            "reminders": filtered
        });
    }

    private async expand(id: string): Promise<void> {
        this.setState({
            editing: id
        })
    }

    private async save(reminder: Reminder): Promise<void> {
        const that = this;
        Storage.save(reminder).then(() => {
            that.setState({
                editing: null
            });

            Api.updateReminder(reminder);
        }).catch(err => {
            console.error(err);
        });
    }

    private loadData(): void {
        const self = this;
        Storage.getAll().then(function (results) {
            self.setState({
                "reminders": results
            });
        }).catch(err => {
            console.error(err);
        })
    }

    render(): React.ReactNode {
        const reminders: Reminder[] = this.state['reminders'];

        return (
            <div>
                <h3>Upcoming reminders</h3>
                <ul className="card-list">
                    {reminders.map(r => {
                            if (r.uuid === this.state['editing']) {
                                return <EditReminder key={r.uuid} reminder={r} handleSave={this.save}/>
                            } else {
                                return <ReminderComponent key={r.uuid} reminder={r} handleDelete={this.remove} handleExpand={this.expand}/>
                            }
                        }
                    )}
                </ul>
            </div>
        );
    }

}
